import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import noData from '../../assets/drill.gif'
import ProductCard from '../../components/productCard'
import { useDataContext } from '../../contexts/DataContext'
import './spare.scss'

function SpareProducts() {
	const { id } = useParams()
	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(true)
	const { i18n, t } = useTranslation()
	const { isLike, addLike, handleShare, addCart } = useDataContext()
	console.log(products);

	useEffect(() => {
		if (id) {
			axios
				.get(
					`https://back2.protools.uz/api/maxsulot/?include=zapchast&filter[zapchast_id]=${id}`
				)
				.then(response => {
					setProducts(response.data.data)
					setLoading(false)
				})
				.catch(error => {
					console.error('Error fetching products:', error)
					setLoading(false)
				})
		}
	}, [id])

	return (
		<div className='spareProducts'>
			<div className='container'>
				{loading ? (
					<p>{t('loading')}</p>
				) : products.length === 0 ? (
					<div className='noData'>
						<img
							src={noData}
							alt='No data'
							width={200}
							height={200}
							style={{ margin: '0 auto', display: 'block' }}
						/>
					</div>
				) : (
					<div className='spareProducts_wrap'>
						{products.map(product => (
							<ProductCard
								key={product.id}
								title={
									i18n.language === 'uz' ? product?.name_uz : product?.name_ru
								}
								image={`https://back2.protools.uz/media/${product?.image}`}
								price={product?.price}
								path={`/spare/product/${product?.id}`}
								excerpt={product?.status}
								pressLike={() => addLike(product)}
								onClick={() => addCart(product)}
								like={isLike(product)}
								handlerShare={() => handleShare(product)}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

export default SpareProducts
